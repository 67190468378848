import { SegmentOptionValue } from '@apps/attention360/pages/strategyReports/StrategyReportUtil';
import { ChannelSegmentResponseDto, FormatSegmentResponseDto } from '@api-clients/attention-data';
import { FlameColorCategory, flameColors } from '@apps/attention360/pages/segment/BubbleColors';
import { getMax, getMin } from '@shared/utils/mathUtils';

const roundDownToNearestFive = (num: number): number => {
    return Math.floor(num / 5) * 5;
};
export const getChartAxisRange = (params: {
    xAxisMin: number;
    xAxisAvg: number;
    xAxisMax: number;
    yAxisMin: number;
    yAxisAvg: number;
    yAxisMax: number;
    selectedSegment: SegmentOptionValue;
}): {
    displayedXAxisMin: number;
    displayedXAxisMax: number;
    displayedYAxisMin: number;
    displayedYAxisMax: number;
} => {
    const { xAxisMin, xAxisAvg, xAxisMax, yAxisMin, yAxisAvg, yAxisMax, selectedSegment } = params;
    switch (selectedSegment) {
        case 'first':
            return {
                displayedXAxisMin: xAxisAvg,
                displayedXAxisMax: xAxisMax,
                displayedYAxisMin: yAxisAvg,
                displayedYAxisMax: yAxisMax,
            };
        case 'second':
            return {
                displayedXAxisMin: xAxisAvg,
                displayedXAxisMax: xAxisMax,
                displayedYAxisMin: yAxisMin,
                displayedYAxisMax: yAxisAvg,
            };
        case 'third':
            return {
                displayedXAxisMin: xAxisMin,
                displayedXAxisMax: xAxisAvg,
                displayedYAxisMin: yAxisMin,
                displayedYAxisMax: yAxisAvg,
            };
        case 'fourth':
            return {
                displayedXAxisMin: xAxisMin,
                displayedXAxisMax: xAxisAvg,
                displayedYAxisMin: yAxisAvg,
                displayedYAxisMax: yAxisMax,
            };
        case 'all':
            return {
                displayedXAxisMin: xAxisMin,
                displayedXAxisMax: xAxisMax,
                displayedYAxisMin: yAxisMin,
                displayedYAxisMax: yAxisMax,
            };
        default:
            throw new Error('Unknown segments');
    }
};

export const segmentGraphNumberFormatter = new Intl.NumberFormat([navigator.language, 'en-US'], {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
});

export type GraphViewOption = 'cpm' | 'channel';
export const graphViewAvailableSelectOptions: { label: string; value: GraphViewOption }[] = [
    {
        label: 'Channel',
        value: 'channel',
    },
    {
        label: 'CPM',
        value: 'cpm',
    },
];

export const getBubbleColorByCpm = (payload: {
    cpm: number | null | undefined;
    minCpm: number;
    maxCpm: number;
}): FlameColorCategory => {
    const { cpm, minCpm, maxCpm } = payload;
    if (!cpm || cpm === 0) {
        return 'gray';
    }
    if (minCpm > maxCpm || minCpm < 0 || maxCpm < 0 || cpm < 0 || cpm < minCpm || cpm > maxCpm) {
        throw new Error('Parameters out of range');
    }
    const step = Math.max((maxCpm - minCpm) / 4, 0);
    let stepper = roundDownToNearestFive(step);
    // special case e.g. minCpm = 0, maxCpm = 100
    if (stepper === step) {
        stepper -= 5;
    }
    // when the diff between maxCpm and minCpm is very small, revert back to uniform distribution
    if (stepper <= 0) {
        stepper = (maxCpm - minCpm) / Object.keys(flameColors).length - 1;
    }
    if (cpm < minCpm + stepper) return 'xs';
    if (cpm < minCpm + stepper * 2) return 'sm';
    if (cpm < minCpm + stepper * 3) return 'md';
    if (cpm < minCpm + stepper * 4) return 'lg';
    return 'xl';
};

export const getFormatLevelSegmentData = (
    channelLevelSegmentData: Array<ChannelSegmentResponseDto>,
): Array<FormatSegmentResponseDto & { adChannelName: string }> => {
    const res: Array<FormatSegmentResponseDto & { adChannelName: string }> = [];
    for (let i = 0; i < channelLevelSegmentData.length; i++) {
        const channelLevelSegment = channelLevelSegmentData[i];
        if (channelLevelSegment.formatSegmentResponseDto) {
            const segmentLevelData: Array<FormatSegmentResponseDto & { adChannelName: string }> =
                channelLevelSegment.formatSegmentResponseDto.map((seg) => ({
                    ...seg,
                    adChannelName: channelLevelSegment.adChannelName ?? '',
                }));
            for (let j = 0; j < segmentLevelData.length; j++) {
                res.push(segmentLevelData[j]);
            }
        }
    }
    return res;
};

type FlameGraphDataPoints = {
    [Key in FlameColorCategory]: {
        data: Array<FormatSegmentResponseDto & { adChannelName: string }>;
        label: string;
    };
};

const getFlameGraphLabel = (payload: {
    key: FlameColorCategory;
    minCpm: number;
    maxCpm: number;
}): string => {
    const { key, minCpm, maxCpm } = payload;
    if (key === 'gray') return 'No CPM/0';
    const step = Math.max((maxCpm - minCpm) / 4, 0);
    let stepper = roundDownToNearestFive(step);
    if (stepper === step) {
        stepper -= 5;
    }
    if (step <= 0) {
        stepper = (maxCpm - minCpm) / Object.keys(flameColors).length - 1;
    }
    if (key === 'xs') return `< ${minCpm + stepper}`;
    if (key === 'sm') return `${minCpm + stepper} - ${minCpm + 2 * stepper}`;
    if (key === 'md') return `${minCpm + 2 * stepper} - ${minCpm + 3 * stepper}`;
    if (key === 'lg') return `${minCpm + 3 * stepper} - ${minCpm + 4 * stepper}`;
    return `${minCpm + 4 * stepper} - ${maxCpm}`;
};

export const getFlameGraphData = (
    segments: Array<ChannelSegmentResponseDto>,
): FlameGraphDataPoints => {
    const formatLevelSegmentData = getFormatLevelSegmentData(segments);
    const maxCpm =
        formatLevelSegmentData.length === 0
            ? 0
            : getMax(formatLevelSegmentData.map((f) => f.cpm ?? 0));
    const minCpm =
        formatLevelSegmentData.length === 0
            ? 0
            : getMin(formatLevelSegmentData.map((f) => f.cpm ?? 0));
    const res: FlameGraphDataPoints = {
        gray: { data: [], label: getFlameGraphLabel({ key: 'gray', minCpm, maxCpm }) },
        xs: { data: [], label: getFlameGraphLabel({ key: 'xs', minCpm, maxCpm }) },
        sm: { data: [], label: getFlameGraphLabel({ key: 'sm', minCpm, maxCpm }) },
        md: { data: [], label: getFlameGraphLabel({ key: 'md', minCpm, maxCpm }) },
        lg: { data: [], label: getFlameGraphLabel({ key: 'lg', minCpm, maxCpm }) },
        xl: { data: [], label: getFlameGraphLabel({ key: 'xl', minCpm, maxCpm }) },
    };
    for (let i = 0; i < formatLevelSegmentData.length; i++) {
        const formatLevelData = formatLevelSegmentData[i];
        const color = getBubbleColorByCpm({ cpm: formatLevelData.cpm, minCpm, maxCpm });
        if (color === 'gray') {
            res.gray.data.push(formatLevelData);
        } else if (color === 'xs') {
            res.xs.data.push(formatLevelData);
        } else if (color === 'sm') {
            res.sm.data.push(formatLevelData);
        } else if (color === 'md') {
            res.md.data.push(formatLevelData);
        } else if (color === 'lg') {
            res.lg.data.push(formatLevelData);
        } else if (color === 'xl') {
            res.xl.data.push(formatLevelData);
        }
    }
    return res;
};

interface NestedText {
    fontSize: string;
    fontWeight: number;
    color: string;
    opacity: number;
    text1: string;
    text2: string;
}

interface SegmentPosition {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
    align: "left" | "right";
    text1: string;
    text2: string;
    nestedText?: NestedText;
}

// Define a type for segment mapping
interface SegmentMapping {
    [key: string]: SegmentPosition[];
}

// Mapping of segments to their respective positions and content
export const segmentMapping: SegmentMapping = {
    all: [
        { top: "7rem", left: "7.5rem", align: "left", text1: "Low retention rate", text2: "high attentive reach", nestedText: { fontSize: ".75rem", fontWeight: 400, color: "#373D51", opacity: 0.5, text1: "Big brands - Brand maintenance", text2: "Short and effective messaging" } },
        { top: "7rem", right: "3.5rem", align: "right", text1: "High retention rate", text2: "high attentive reach", nestedText: { fontSize: ".75rem", fontWeight: 400, color: "#373D51", opacity: 0.5, text1: "Challenger brands - Brand building", text2: "Storytelling with emotional cues" } },
        { bottom: "10rem", left: "7.5rem", align: "left", text1: "Low retention rate", text2: "low attentive reach", nestedText: { fontSize: ".75rem", fontWeight: 400, color: "#373D51", opacity: 0.5, text1: "Less active and passive attention", text2: "Short and effective messaging, frequency required" } },
        { bottom: "10rem", right: "3.5rem", align: "right", text1: "High retention rate", text2: "low attentive reach", nestedText: { fontSize: ".75rem", fontWeight: 400, color: "#373D51", opacity: 0.5, text1: "Challenger brands - Brand building", text2: "Storytelling with emotional cues, frequency required" } }
    ],
    first: [
        { top: "7rem", right: "3.5rem", align: "right", text1: "High retention rate", text2: "high attentive reach", nestedText: { fontSize: ".75rem", fontWeight: 400, color: "#373D51", opacity: 0.5, text1: "Challenger brands - Brand building", text2: "Storytelling with emotional cues" } }
    ],
    second: [
        { bottom: "10rem", right: "3.5rem", align: "right", text1: "High retention rate", text2: "low attentive reach", nestedText: { fontSize: ".75rem", fontWeight: 400, color: "#373D51", opacity: 0.5, text1: "Challenger brands - Brand building", text2: "Storytelling with emotional cues, frequency required" } }
    ],
    third: [
        { bottom: "10rem", left: "7.5rem", align: "left", text1: "Low retention rate", text2: "low attentive reach", nestedText: { fontSize: ".75rem", fontWeight: 400, color: "#373D51", opacity: 0.5, text1: "Less active and passive attention", text2: "Short and effective messaging, frequency required" } }
    ],
    fourth: [
        { top: "7rem", left: "7.5rem", align: "left", text1: "Low retention rate", text2: "high attentive reach", nestedText: { fontSize: ".75rem", fontWeight: 400, color: "#373D51", opacity: 0.5, text1: "Big brands - Brand maintenance", text2: "Short and effective messaging" } }
    ]
};