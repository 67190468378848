import { BrandedMomentsResponseDto, DecaySecondResponseDto } from '@api-clients/attention-data';
import { Box, useToken } from '@chakra-ui/react';
import { toLocaleFixed } from '@shared/utils';
import { FC } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, LegendItem } from 'chart.js';
import { getFillColorByLabel } from '@apps/attention360/pages/strategyReports/StrategyReportUtil';
import { colors } from '@assets/design-system/theming/colors';

interface FormatDecayGraphProps {
    decaySeconds: DecaySecondResponseDto[];
    brandedMoments?: BrandedMomentsResponseDto[];
    creativeDuration?: number;
    showBrandedMomentsLegend?: boolean;
}

export const FormatDecayGraph: FC<FormatDecayGraphProps> = ({
    decaySeconds,
    brandedMoments,
    creativeDuration,
    showBrandedMomentsLegend = false,
}) => {
    const [green500, green400] = useToken('colors', ['green.500', 'green.400']);

    return (
        <Box height="32rem" bgColor="white" padding="2rem">
            <Line
                plugins={[]}
                data={{
                    // Second 1 from db == the data point at 0 in x-axis
                    labels: decaySeconds.map((d) => (d.second ?? 1) - 1),
                    datasets: [
                        {
                            label: 'Active attention',
                            tension: 0.1,
                            backgroundColor: green500,
                            borderColor: green500,
                            data: decaySeconds?.map(
                                (d) => (d.activeAttentionPercentage ?? 0) * 100,
                            ),
                            order: 1,
                        },
                        {
                            label: 'Passive attention',
                            tension: 0.1,
                            backgroundColor: green400,
                            borderColor: green400,
                            data: decaySeconds?.map(
                                (d) => (d.passiveAttentionPercentage ?? 0) * 100,
                            ),
                            order: 2,
                        },
                    ],
                }}
                options={{
                    plugins: {
                        legend: {
                            reverse: false,
                            display: true,
                            labels: {
                                generateLabels(chart: Chart): LegendItem[] {
                                    const originalLabels: LegendItem[] = chart.data.datasets.map(
                                        (dataset, i) => ({
                                            text: dataset.label ?? '',
                                            borderRadius: 0,
                                            fillStyle: getFillColorByLabel(dataset.label ?? ''),
                                            hidden: !chart.isDatasetVisible(i),
                                            lineCap: 'round',
                                            lineDash: [],
                                            lineDashOffset: 0,
                                            lineJoin: 'round',
                                            lineWidth: 0,
                                            strokeStyle: 'white',
                                            pointStyle: 'circle',
                                            datasetIndex: i,
                                            rotation: 0,
                                        }),
                                    );
                                    const active = originalLabels.find(
                                        (item) =>
                                            item.text.toLowerCase().startsWith('active') &&
                                            !item.text.toLowerCase().endsWith('cpm'),
                                    );
                                    const passive = originalLabels.find((item) =>
                                        item.text.toLowerCase().startsWith('passive'),
                                    );
                                    if (!active || !passive) {
                                        throw new Error('Something went wrong');
                                    }
                                    if (!showBrandedMomentsLegend) {
                                        return [active, passive];
                                    }
                                    return [
                                        active,
                                        passive,
                                        {
                                            text: 'Branded moments',
                                            fillStyle: colors.colors.orange['500'],
                                            lineCap: 'round',
                                            lineDash: [],
                                            lineDashOffset: 0,
                                            lineJoin: 'round',
                                            lineWidth: 0,
                                            strokeStyle: 'white',
                                            pointStyle: 'circle',
                                            rotation: 0,
                                            borderRadius: 0,
                                        },
                                    ];
                                },
                            },
                        },
                        tooltip: {
                            mode: 'index',
                            callbacks: {
                                title: (tooltipItems) => `Second ${tooltipItems[0].label}`,
                                label: (tooltipItem) =>
                                    ` ${toLocaleFixed(
                                        tooltipItem.raw as number,
                                        1,
                                    )}% ${tooltipItem.dataset.label?.toLowerCase()}`,
                            },
                            displayColors: true,
                        },
                        annotation: {
                            annotations: brandedMoments?.map((b) => ({
                                type: 'box',
                                xMin: b.start,
                                xMax: b.end,
                                yMin: 0,
                                yMax: 100,
                                borderColor: 'rgba(0, 0, 0, 0)',
                                backgroundColor: 'rgba(232, 106, 63,0.1)',
                                drawTime: 'beforeDatasetsDraw',
                            })),
                        },
                    },
                    interaction: {
                        mode: 'nearest',
                        axis: 'x',
                        intersect: false,
                    },
                    scales: {
                        x: {
                            title: { display: true, text: 'Time (seconds)' },
                            min: 0,
                            max: creativeDuration || decaySeconds.length,
                            type: 'linear',
                            position: 'bottom',
                        },
                        y: {
                            title: { display: true, text: 'Attention distribution (%)' },
                            stacked: true,
                            min: 0,
                            max: 100,
                            type: 'linear',
                            position: 'left',
                            ticks: {
                                callback: (tickValue, index, ticks) => `${tickValue}%`,
                            },
                        },
                    },
                }}
            />
        </Box>
    );
};
