import {
    CampaignResponseDto,
    CreateOptimisedScenariosRequestDto,
    GetCampaignByIdRequest,
    GetCampaignResponseDto,
} from '@api-clients/media-plan';
import {
    Button,
    Center,
    Container,
    HStack,
    Icon,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList, Spacer,
    Text,
    useBoolean,
    useDisclosure
} from '@chakra-ui/react';
import {
    ExpandableBanner,
    ExpandableBannerBody,
    ExpandableBannerHeader,
} from '@components/atoms/expandableBanner/ExpandableBanner';
import { CampaignHeaderSection } from '@components/organisms';
import { useGetCampaignConstraints } from '@hooks/campaigns/useGetCampaignConstraints';
import React, { FC, useEffect, useState } from 'react';
import { useDeleteCampaign, useGetCampaign } from '@hooks/campaigns';
import { routing } from "@configs";
import { EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useCustomToast } from "@hooks/toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog } from "@components/atoms/confirmDialog/ConfirmDialog";
import { ShareMediaPlanButton } from "@apps/attentionADJUST/components/molecules/manageScenarioMenu/ShareMediaPlanButton";
import { ConstraintsSection } from '../../molecules';

export interface CampaignBannerProps {
    campaignId: string;
    onCreateScenarios: (scenario: CreateOptimisedScenariosRequestDto) => Promise<void>;
}

export const CampaignBanner: FC<CampaignBannerProps> = ({ campaignId, onCreateScenarios }) => {
    const { getCampaignConstraints } = useGetCampaignConstraints();
    const { getCampaign } = useGetCampaign();
    const [campaign, setCampaign] = useState<GetCampaignResponseDto>();
    const [campaignDetail, setCampaignDetail] = useState<CampaignResponseDto>();
    const [isFetching, setIsFetching] = useBoolean(false);
    const [isDeletingCampaign, setIsDeletingCampaign] = useBoolean();
    const { errorToast, successToast } = useCustomToast();
    const { deleteCampaign: deleteMediaPlan } = useDeleteCampaign();
    const navigate = useNavigate();

    useEffect(() => {
        setIsFetching.on();
        getCampaignConstraints(campaignId).then((r) => setCampaign(r));
        const request: GetCampaignByIdRequest = { mediaPlanId: campaignId };
        getCampaign(request).then((r) => setCampaignDetail(r));
        setIsFetching.off();
    }, []);

    const { t: mediaPlansTranslation } = useTranslation('mediaPlans');
    const mediaPlanContent = mediaPlansTranslation('mediaPlanning.campaignManagement', {
        returnObjects: true,
    });

    const {
        isOpen: deleteMediaPlanDialogIsOpen,
        onOpen: deleteMediaPlanDialogOnOpen,
        onClose: deleteMediaPlanDialogOnClose,
    } = useDisclosure();

    const handleDeleteMediaPlan = async (isConfirmation: boolean) => {
        if (!isConfirmation) {
            deleteMediaPlanDialogOnClose();
            return;
        }

        setIsDeletingCampaign.on();
        try {
            await deleteMediaPlan(campaignId);
            successToast(mediaPlanContent.toast.success.heading, mediaPlanContent.toast.success.subtitle);
        } catch (e) {
            errorToast(mediaPlanContent.toast.error.heading, mediaPlanContent.toast.error.subtitle);
        } finally {
            setIsDeletingCampaign.off();
            deleteMediaPlanDialogOnClose();

            navigate(`/${routing.campaign.root.path}`);
        }
    };

    return (
        <>
            <ConfirmDialog
                isOpen={deleteMediaPlanDialogIsOpen}
                onClose={(isConfirmed) => handleDeleteMediaPlan(isConfirmed)}
                headerText="Delete media plan"
                isDestructive
                isLoading={isDeletingCampaign}
                confirmButtonText="Delete"
                colorScheme="red"
                cancelButtonText="Cancel"
            >
                <Text color="gray.700">You cannot undo this action</Text>
            </ConfirmDialog>
            <ExpandableBanner>
                <ExpandableBannerHeader>
                    <Container>
                        <HStack justifyContent="space-between">
                            <CampaignHeaderSection
                                title={campaign?.campaignName ?? ''}
                                subtitle={campaign?.clientName ?? ''}
                                lastModified={campaign?.updatedAt}
                                isLoading={!campaign}
                            />
                            <Spacer />
                            <Menu>
                                <MenuButton
                                    color="gray.400"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    as={IconButton}
                                    isRound
                                    variant="ghost"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    icon={<EllipsisVerticalIcon />}
                                    size="sm"
                                />
                                <MenuList minH="48px">
                                    <MenuItem
                                        onClick={() => {
                                            deleteMediaPlanDialogOnOpen();
                                        }}
                                        icon={
                                            <Center>
                                                <Icon as={TrashIcon} boxSize="1.5rem" />
                                            </Center>
                                        }
                                    >
                                        Delete Media Plan
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                    </Container>
                </ExpandableBannerHeader>
                <ExpandableBannerBody>
                    <Container>
                        <ConstraintsSection campaign={campaign} onCreateScenarios={onCreateScenarios} />
                    </Container>
                </ExpandableBannerBody>
            </ExpandableBanner>
        </>
    );
};
