import { StrategyReportResponseDto } from '@api-clients/attention-data';
import {
    NavLinkStepperButton,
    NavLinkStepperButtonProps,
} from '@apps/attention360/atoms/navLinkStepperButton/NavLinkStepperButton';
import { Button, Card, CardBody, Container, Flex, HStack, Icon, Stack } from '@chakra-ui/react';
import { Loading } from '@components/atoms';
import { routing } from '@configs';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { useGetStrategyReports } from '@hooks/strategyReports';
import { useCustomToast } from '@hooks/toast';
import { FC, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { StrategyReportContextProvider } from './StrategyReportContextProvider';

type StrategyReportPageProps = {};
export const StrategyReportPage: FC<StrategyReportPageProps> = () => {
    const { strategyReportId } = useParams();
    const { getStrategyReports } = useGetStrategyReports();

    const { errorToast } = useCustomToast();

    const [strategyReport, setStrategyReport] = useState<StrategyReportResponseDto>();

    useEffect(() => {
        let ignore = false;
        if (strategyReportId && strategyReportId.length > 0) {
            setStrategyReport(undefined);
            getStrategyReports({ strategyReportId })
                .then((s) => {
                    if (!ignore) setStrategyReport(s);
                })
                .catch(() => errorToast('Something went wrong', 'Please try again later'));
        }

        return () => {
            ignore = true;
        };
    }, [strategyReportId]);

    const tabButtons: NavLinkStepperButtonProps[] = [
        {
            url: routing.strategyReports.view.channelSelection.path,
            title: 'Step 1',
            description: 'Channel selection',
        },
        {
            url: routing.strategyReports.view.formatSelection.path,
            title: 'Step 2',
            description: 'Format selection',
        },
        {
            url: routing.strategyReports.view.audienceAnalysis.path,
            title: 'Step 3',
            description: 'Audience analysis',
        },
        {
            url: routing.strategyReports.view.creativeRecommendations.path,
            title: 'Step 4',
            description: 'Creative recommendations',
        },
    ];

    return strategyReport ? (
        <StrategyReportContextProvider
            strategyReport={strategyReport}
            setStrategyReport={setStrategyReport}
        >
            <Container>
                <Stack gap="1rem">
                    <Card>
                        <CardBody>
                            <Flex gap="1rem" flexGrow={1}>
                                {tabButtons.map((props) => (
                                    <NavLinkStepperButton key={props.url} {...props} />
                                ))}
                            </Flex>
                        </CardBody>
                    </Card>
                    <Outlet />
                </Stack>
            </Container>
        </StrategyReportContextProvider>
    ) : (
        <Loading loadingText="Loading strategy report" />
    );
};
