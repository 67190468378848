import {
    CreativeResponseDto,
    DecaySecondResponseDto,
    FormatDecayResponseDto,
} from '@api-clients/attention-data';
import { SortOption } from '@apps/attention360/atoms/sortOption/SortOption';
import { Box, Center, Flex, Spacer, Text } from '@chakra-ui/react';
import { AsyncCard } from '@components/atoms';
import { Column, DataTable, Row } from '@components/molecules';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useGetFormatDecayForAllFormats } from '@hooks/strategyReport/useGetFormatDecayForAllFormats';
import { toLocaleFixed } from '@shared/utils';
import { FC, useEffect, useState } from 'react';
import { CreativeRecommendationSortOptionValue } from '@apps/attention360/pages/strategyReports/CreativeRecommendationsSection';
import { sortIsSelected } from '@apps/attention360/pages/strategyReports/CreativeRecommendationUtil';
import { FormatDecayGraph } from './FormatDecayGraph';
import { InlineChart } from './InlineChart';
import { useStrategyReportContext } from './StrategyReportContextProvider';
import { numberFormatter } from './StrategyReportUtil';

interface DataRow {
    adChannelCode: string;
    adChannelName: string;
    adFormatCode: string;
    adFormatName: string;
    minDuration: number;
    maxDuration: number;
    retentionRate: number;
    aaForBrandedMoments?: number;
    paForBrandedMoments?: number;
    decayCurve: Array<DecaySecondResponseDto>;
    isSelected: boolean;
}
export interface AllFormatsViewProps {
    selectedCreative?: CreativeResponseDto;
    showOnlySelectedFormats?: boolean;
    formatDecayResponses?: FormatDecayResponseDto[];
    selectedSortOption: CreativeRecommendationSortOptionValue;
    selectedCreativeSortOption: CreativeRecommendationSortOptionValue;
    dataSource: Row<DataRow>[];
}

export const AllFormatsView: FC<AllFormatsViewProps> = ({
    selectedCreative,
    showOnlySelectedFormats = false,
    formatDecayResponses,
    selectedCreativeSortOption,
    selectedSortOption,
    dataSource,
}) => {
    const brandedMomentsColumns: Array<Column<DataRow>> = [
        {
            title: 'Active attention on branded moments',
            dataIndex: 'aaForBrandedMoments',
            isNumeric: true,
            key: 'aaForBrandedMoments',
            render: (val) => (
                <>
                    {val.aaForBrandedMoments !== undefined
                        ? toLocaleFixed(val.aaForBrandedMoments)
                        : 'N/A'}
                </>
            ),
        },
        {
            title: 'Passive attention on branded moments',
            dataIndex: 'paForBrandedMoments',
            isNumeric: true,
            key: 'paForBrandedMoments',
            render: (val) => (
                <>
                    {val.paForBrandedMoments !== undefined
                        ? toLocaleFixed(val.paForBrandedMoments)
                        : 'N/A'}
                </>
            ),
        },
    ];

    const columns: Array<Column<DataRow>> = [
        {
            title: 'Channel',
            dataIndex: 'adChannelName',
            isNumeric: false,
            key: 'channelName',
            render: (row) => {
                const selectedTextStyle = {
                    color: '#191919',
                    fontWeight: 600,
                };

                const unselectedTextStyle = {
                    color: '#4A4B4D',
                    fontWeight: 500,
                };

                return (
                    <Flex alignItems="center">
                        <Text
                            as="span"
                            style={row.isSelected ? selectedTextStyle : unselectedTextStyle}
                        >
                            {row.adChannelName}
                        </Text>
                    </Flex>
                );
            },
        },
        {
            title: 'Format',
            dataIndex: 'adFormatName',
            isNumeric: false,
            key: 'adFormatName',
            render: (row) => {
                const baseStyle = {
                    display: 'inline-block',
                    marginRight: '8px',
                };

                const selectedDotStyle = {
                    ...baseStyle,
                    width: '8px',
                    height: '8px',
                    backgroundColor: '#00AC59',
                    borderRadius: '50%',
                };

                const selectedTextStyle = {
                    color: '#191919',
                    fontWeight: 600,
                };

                const unselectedTextStyle = {
                    color: '#4A4B4D',
                    fontWeight: 500,
                };

                return (
                    <Flex alignItems="center">
                        {row.isSelected && <Text as="span" style={selectedDotStyle} />}
                        <Text
                            as="span"
                            style={row.isSelected ? selectedTextStyle : unselectedTextStyle}
                        >
                            {row.adFormatName}
                        </Text>
                    </Flex>
                );
            },
        },
        {
            title: 'Min duration',
            dataIndex: 'minDuration',
            isNumeric: true,
            key: 'minDuration',
        },
        {
            title: 'Max duration',
            dataIndex: 'maxDuration',
            isNumeric: true,
            key: 'maxDuration',
        },
        ...(selectedCreative ? brandedMomentsColumns : []),
        {
            title: 'Retention rate',
            dataIndex: 'retentionRate',
            isNumeric: true,
            key: 'retentionRate',
            render: (row) => {
                return <>{row.retentionRate ? numberFormatter.format(row.retentionRate) : 'N/A'}</>;
            },
            tooltip: 'How well attention retains over the viewing session',
        },
        {
            title: 'Decay curve',
            dataIndex: 'decayCurve',
            isNumeric: false,
            key: 'decayCurve',
            render: (row) =>
                row.decayCurve.length === 0 ? (
                    <span>N/A</span>
                ) : (
                    <InlineChart entries={row.decayCurve} />
                ),
            tooltip: 'A graph of how viewers pay attention over the viewing session',
        },
    ];

    return (
        <>
            <AsyncCard isLoading={!formatDecayResponses} mt="1rem">
                {formatDecayResponses && <DataTable dataSource={dataSource} columns={columns} />}
            </AsyncCard>
        </>
    );
};
